import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledBoxRow, StyledSpan} from "../../../shared/StyledComponents";
import {
  colorBlue, colorDarkBrown, colorDarkPink,
  colorDarkPurple, colorEarthRed,
  colorGreen,
  colorHighlightAlt,
  colorLightGreen,
  colorOrange,
  colorPink, colorPurple,
  colorRed,
  colorYellow,
  gray
} from "../../../shared/colors";
import {DIVIDER_BLACK, PD_LG, PD_SM, SZ_JUMBO, SZ_MD, SZ_SM} from "../../../shared/dimens";
import {AbstractFragment} from "./AbstractFragment";
import {BaseFragmentProps, BaseFragmentState} from "../../../shared/BaseFragment";
import {MainMode} from "../../Main";
import {JsonObject} from "../../../shared/json/json-object";
import {JsonProperty} from "../../../shared/json/json-property";
import {ButtonBase, Card, Fab, IconButton, Typography} from "@mui/material";
import {EditOutlined} from "@mui/icons-material";
import {UserProfilePhoto} from "../../../shared/types";
import {UserFragment} from "../../../shared/UserFragment";
import {App} from "../../App";
import {getMemberAuth} from "../../../shared/auth";

@JsonObject()
class Sobject {

  @JsonProperty()
  index: number;

  @JsonProperty()
  name: string;

  static createNew(index: number, name: string): Sobject {
    const obj = new Sobject();
    obj.index = index;
    obj.name = name;
    return obj;
  }
}

@JsonObject()
class Page {

  @JsonProperty()
  readonly index: number;

  constructor(index: number) {
    this.index = index;
  }
}

export type EditorProps = BaseFragmentProps & {
  setMode: (mode: MainMode) => void,
}

export type EditorState = BaseFragmentState & {
  selectedObjectIndex: number,
  objects: Sobject[],
  selectedPageIndex: number,
  pages: Page[],
}

export class Editor extends AbstractFragment<EditorProps, EditorState> {

  private readonly memberAuth = getMemberAuth();

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    this.setState({
      selectedObjectIndex: 0,
      objects: [Sobject.createNew(0, "Doggo")],
      selectedPageIndex: 0,
      pages: [new Page(0)],
    });
  }

  protected renderContent(): React.ReactElement {
    return <>
      <StyledBoxRow style={{padding: PD_LG, position: "relative", alignItems: "center"}}>
        <img src={"/stamp.png"} style={{height: 40}}/>
        <StyledSpan/>
        {this.renderButton("upload-03", colorBlue, () => {}, true)}
        {this.renderButton("floppy-disk", colorBlue, () => {}, true)}
        {this.renderButton("help-square", colorBlue, () => {}, true)}
        <StyledSpan/>
        {this.renderButton("maximize-screen", colorOrange, () => {}, true)}
        {this.renderButton("layout-grid", colorOrange, () => {}, true)}
        <StyledSpan/>
        {this.renderButton("image-01", colorYellow, () => {})}
        {this.renderButton("text-square", colorYellow, () => {})}
        <StyledSpan/>
        {this.renderButton("restore-bin", colorLightGreen, () => {}, true)}
        {this.renderButton("play", colorGreen, () => {}, true)}
        <StyledSpan/>
        <Card style={{width: SZ_SM, height: SZ_SM, flexShrink: 0}}>
          <ButtonBase
            onClick={() => App.CONTEXT.showDialog(null, () => <UserFragment uid={this.memberAuth.member.uid}/>)}>
            <img src={UserProfilePhoto(this.memberAuth.member.user)} style={{width: "100%", height: "100%"}}/>
          </ButtonBase>
        </Card>
      </StyledBoxRow>
      <StyledBoxRow style={{flexGrow: 1, marginTop: -16}}>
        <StyledBoxColumn style={{width: SZ_JUMBO, flexShrink: 0, gap: 0}}>
          <StyledBoxColumn style={{alignItems: "center", padding: PD_LG}}>
            {this.renderButton("add-01", colorDarkPurple, () => {}, true)}
          </StyledBoxColumn>
          <div style={{alignSelf: "center", width: 96, borderTop: DIVIDER_BLACK}}/>
          <StyledBoxColumn style={{padding: PD_LG, alignItems: "center", maxHeight: 320, overflowY: "scroll"}}>
            {this.state.objects.map(page => this.renderObjectThumbnail(page))}
          </StyledBoxColumn>
        </StyledBoxColumn>
        <StyledBoxColumn style={{flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
          <div style={{height: 400, aspectRatio: 3 / 2, border: DIVIDER_BLACK, background: "white"}}>
            <img src={"/assets/scenes/doggo_scene.png"} style={{width: "100%", aspectRatio: 3/2, objectFit: "cover"}}/>
          </div>
        </StyledBoxColumn>
        <StyledBoxColumn style={{width: SZ_JUMBO, flexShrink: 0, gap: 0}}>
          <StyledBoxColumn style={{alignItems: "center", padding: PD_LG}}>
            {this.renderButton("add-01", colorDarkPurple, () => {}, true)}
          </StyledBoxColumn>
          <div style={{alignSelf: "center", width: 96, borderTop: DIVIDER_BLACK}}/>
          <StyledBoxColumn style={{padding: PD_LG, alignItems: "center", maxHeight: 320, overflowY: "scroll"}}>
            {this.state.pages.map(page => this.renderPageThumbnail(page))}
          </StyledBoxColumn>
        </StyledBoxColumn>
      </StyledBoxRow>
      <StyledBoxColumn style={{height: SZ_JUMBO, flexShrink: 0, gap: 0}}>
        <StyledBoxRow
          style={{height: SZ_MD, background: gray, borderTop: DIVIDER_BLACK, gap: 0, borderBottom: DIVIDER_BLACK}}>
          <StyledBoxRow style={{alignItems: "center", padding: PD_SM, borderRight: DIVIDER_BLACK}}>
            {this.renderButton("flag-03", colorYellow, () => {})}
            {this.renderButton("move-right", colorBlue, () => {}, true)}
            {this.renderButton("super-mario-toad", colorPink, () => {}, true)}
            {this.renderButton("volume-high", colorGreen, () => {}, true)}
            {this.renderButton("flow", colorOrange, () => {}, true)}
            {this.renderButton("ticket-03", colorEarthRed, () => {}, true)}
          </StyledBoxRow>
          <StyledBoxRow style={{alignItems: "center", background: colorYellow, flexGrow: 1, padding: PD_SM}}>
          </StyledBoxRow>
          <StyledBoxRow style={{alignItems: "center", padding: PD_SM, borderLeft: DIVIDER_BLACK}}>
            {this.renderButton("arrow-turn-forward", colorBlue, () => {}, true, {transform: "scale(-1)"})}
            {this.renderButton("arrow-turn-backward", colorBlue, () => {}, true, {transform: "scale(-1)"})}
          </StyledBoxRow>
        </StyledBoxRow>
        <StyledBoxRow style={{flexGrow: 1, background: "white"}}>
          <img src={"/assets/objects/animals/doggo.svg"} style={{height: 160, aspectRatio: 1, opacity: 0.5, filter: "grayscale(1)"}}/>
          <img src={"/assets/scenes/blocks.png"} style={{height: 160}}/>
        </StyledBoxRow>
      </StyledBoxColumn>
    </>;
  }

  private renderObjectThumbnail(object: Sobject): ReactElement {
    const selected = this.state.selectedObjectIndex === object.index;
    return <ButtonBase
      style={{position: "relative"}}
      onClick={() => this.setState({selectedObjectIndex: object.index})}>
      <Card
        style={{
          width: 128,
          border: DIVIDER_BLACK,
          padding: PD_SM,
          background: selected ? colorHighlightAlt : "white",
        }}>
        <StyledBoxColumn>
          <Typography variant="h6">{object.name}</Typography>
          <img src={"/assets/objects/animals/doggo.svg"} style={{width: "100%", aspectRatio: 3 / 2, background: "white"}}/>
        </StyledBoxColumn>
      </Card>
      <IconButton onClick={event => {
        event.stopPropagation();
        this.props.setMode(MainMode.DRAWPAD);
      }} style={{position: "absolute", right: -12, top: -12, background: colorYellow}}>
        <EditOutlined/>
      </IconButton>
    </ButtonBase>;
  }

  private renderPageThumbnail(page: Page): ReactElement {
    const selected = this.state.selectedPageIndex === page.index;
    return <ButtonBase onClick={() => this.setState({selectedPageIndex: page.index})}>
      <Card
        style={{width: 128, border: DIVIDER_BLACK, padding: PD_SM, background: selected ? colorHighlightAlt : "white"}}>
        <StyledBoxColumn>
          <Typography variant="h6">{page.index}</Typography>
          <img src={"/assets/scenes/doggo_scene.png"} style={{width: "100%", aspectRatio: 3 / 2, background: "white"}}/>
        </StyledBoxColumn>
      </Card>
    </ButtonBase>;
  }
}

