// export const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyD4PW-EkfMYF5t-Uq7vft3jreIGksPzCOs",
//   authDomain: "plugins-playground-7971a.firebaseapp.com",
//   projectId: "plugins-playground-7971a",
//   storageBucket: "plugins-playground-7971a.appspot.com",
//   messagingSenderId: "66818322724",
//   appId: "1:66818322724:web:c03ec9054d26ffb40e83b1",
//   measurementId: "G-H0VZ9TTFMY"
// };

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCkR3652BwGpq9zPGylOOvokRqd5TMQf68",
  authDomain: "stackables-apps.firebaseapp.com",
  databaseURL: "https://stackables-apps-default-rtdb.firebaseio.com",
  projectId: "stackables-apps",
  storageBucket: "stackables-apps.appspot.com",
  messagingSenderId: "251499497034",
  appId: "1:251499497034:web:afd46a48bad717e59d88b3",
  measurementId: "G-C97NY0ZKWH"
};