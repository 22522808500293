import {AppConfig, BaseApp, BaseAppProps, BaseAppState} from "shared/BaseApp";
import React, {ReactElement} from 'react';
import {createTheme, ThemeOptions} from "@mui/material";
import {DEFAULT_THEME_OPTIONS, lightGray} from "../shared/colors";
import {Main} from "./Main";
import {PathComponent} from "../index";
import {FIREBASE_CONFIG} from "../consts";
import {BORDER_RADIUS, PD_LG, PD_XSM} from "../shared/dimens";
import {Auth, indexedDBLocalPersistence, initializeAuth} from "@firebase/auth";
import {FirebaseApp} from "firebase/app";
import {Capacitor} from "@capacitor/core";

export type AppProps = BaseAppProps & {}

type AppState = BaseAppState & {}

export const COLOR_PRIMARY = "#111";
export const COLOR_SECONDARY = "#fff";
export const COLOR_BG1 = lightGray;

export const THEME_OPTIONS: ThemeOptions = {
  ...DEFAULT_THEME_OPTIONS,
  components: {
    ...DEFAULT_THEME_OPTIONS.components,
    MuiButton: {
      ...DEFAULT_THEME_OPTIONS.components.MuiButton,
      styleOverrides: {
        root: {
          fontWeight: "600",
          borderRadius: 0,
          fontSize: "100%",
          paddingTop: PD_XSM,
          paddingBottom: PD_XSM,
          paddingLeft: PD_LG,
          paddingRight: PD_LG,
          textTransform: "none",
        },
      }
    },
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  typography: {
    ...DEFAULT_THEME_OPTIONS.typography,
    fontFamily: 'Sen, sans-serif',
    fontSize: 15,
  },
  palette: {
    primary: {
      main: COLOR_PRIMARY,
      contrastText: "#fff",
    },
    secondary: {
      main: COLOR_SECONDARY,
      contrastText: "#000",
    },
    background: {
      default: COLOR_BG1,
    },
  }
};

export const THEME = createTheme(THEME_OPTIONS);

export class App extends BaseApp<AppProps, AppState> {

  protected getAuth(app: FirebaseApp): Auth {
    return Capacitor.isNativePlatform()
      ? initializeAuth(app, {
        persistence: indexedDBLocalPersistence
      })
      : super.getAuth(app);
  }

  onCreateAppConfig(): AppConfig {
    return {
      name: "Stackables",
      icon: "/icon.png",
      logo: "/logo192.png",
      stamp: "/stamp.png",
      stampHeight: 48,
      defaultUserImage: "/images/default_user.png",
      safeAreaInsets: {
        bottom: Capacitor.getPlatform() === "ios" ? 12 : 0,
      },
      theme: THEME,
      loginConfig: {
        provisioning: {
          signupDisabled: true,
        },
      },
      firebaseConfig: {options: FIREBASE_CONFIG},
      keyboardConfig: {
        shortcuts: {
          sections: [
            {
              title: "Controls",
              shortcuts: [
                {
                  text: "Play",
                  kbd: ["Cmd", "Return"],
                },
                {
                  text: "Code",
                  kbd: ["Cmd", "Shift", "Return"],
                },
              ]
            }
          ]
        }
      },
    };
  }

  static nestedPaths(): PathComponent[] {
    return Main.nestedPaths();
  }

  protected renderMain(): ReactElement {
    return <>
      <Main path={this.props.path}/>
    </>;
  }
}
