import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "../../../shared/BaseFragment";
import React, {ReactElement} from "react";
import {IconButton} from "@mui/material";
import {Icon} from "@iconify/react";
import {colorAlabaster} from "../../../shared/colors";
import {StyledBoxColumn} from "../../../shared/StyledComponents";

export abstract class AbstractFragment<P extends BaseFragmentProps = BaseFragmentProps, S extends BaseFragmentState = BaseFragmentState> extends BaseFragment<P, S> {

  protected renderButton(name: string, color: string, onClick: () => void, dark?: boolean, style?: any): ReactElement {
    return <IconButton style={{background: color}} onClick={onClick}>
      <Icon
        icon={"hugeicons:" + name}
        height={48}
        style={{color: dark ? "white" : "black", ...style}}
      />
    </IconButton>;
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <StyledBoxColumn style={{width: "100vw", height: "100vh", background: colorAlabaster}}>
      {this.renderContent()}
    </StyledBoxColumn>
  }

  protected abstract renderContent(): ReactElement;
}