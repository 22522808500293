import type {SVGProps} from 'react';
import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledBoxRow, StyledEditableTypography, StyledSpan} from "../../../shared/StyledComponents";
import {colorBlue, colorGreen, colorOrange, colorPastelRed, colorYellow} from "../../../shared/colors";
import {Grid, IconButton} from "@mui/material";
import {COLORS} from "./types";
import {DIVIDER_BLACK, PD_LG} from "../../../shared/dimens";
import {AbstractFragment} from "./AbstractFragment";
import {BaseFragmentProps} from "../../../shared/BaseFragment";
import {MainMode} from "../../Main";

export function HugeiconsSolidLine01(props: SVGProps<SVGSVGElement> & { strokeWidth: number }) {
  return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={props.strokeWidth}
          d="M2.5 12h19" color="currentColor"></path>
  </svg>);
}

export type DrawpadProps = BaseFragmentProps & {
  setMode: (mode: MainMode) => void,
}

export class Drawpad extends AbstractFragment<DrawpadProps> {

  private renderCustomIconButton(view: ReactElement, color: string): ReactElement {
    return <IconButton style={{background: color}}>
      {view}
    </IconButton>;
  }

  private renderPaletteColor(color: string): ReactElement {
    return <IconButton style={{background: color, width: 40, height: 40, border: "2px solid #666"}}/>
  }

  protected renderContent(): React.ReactElement {
    return <>
      <StyledBoxRow style={{padding: PD_LG, position: "relative"}}>
        {this.renderButton("arrow-turn-forward", colorBlue, () => {}, true, {transform: "scale(-1)"})}
        {this.renderButton("arrow-turn-backward", colorBlue, () => {}, true, {transform: "scale(-1)"})}
        <StyledSpan/>
        {this.renderButton("tick-04", colorGreen, () => this.props.setMode(MainMode.HOME), true)}
        <StyledBoxColumn style={{
          justifyContent: "center",
          position: "absolute",
          left: "25%",
          top: 0,
          bottom: 0,
          right: "25%",
        }}>

          <StyledEditableTypography
            variant="h4"
            style={{background: "white", border: DIVIDER_BLACK, textAlign: "center"}}/>
        </StyledBoxColumn>
      </StyledBoxRow>
      <StyledBoxRow style={{flexGrow: 1, marginTop: -16}}>
        <StyledBoxColumn style={{padding: PD_LG}}>
          {this.renderButton("circle", colorYellow, () => {})}
          {this.renderButton("rectangular", colorYellow, () => {})}
          {this.renderButton("triangle", colorYellow, () => {})}
          {this.renderCustomIconButton(<HugeiconsSolidLine01 height={48} color="black"
                                                             strokeWidth={2}/>, colorPastelRed)}
          {this.renderCustomIconButton(<HugeiconsSolidLine01 height={48} color="black"
                                                             strokeWidth={4}/>, colorPastelRed)}
          {this.renderCustomIconButton(<HugeiconsSolidLine01 height={48} color="black"
                                                             strokeWidth={6}/>, colorPastelRed)}
          {this.renderCustomIconButton(<HugeiconsSolidLine01 height={48} color="black"
                                                             strokeWidth={8}/>, colorPastelRed)}
        </StyledBoxColumn>
        <StyledBoxColumn style={{flexGrow: 1, alignItems: "center"}}>
          <div style={{height: "100%", aspectRatio: 3 / 2, border: DIVIDER_BLACK, background: "white"}}/>
        </StyledBoxColumn>
        <StyledBoxColumn style={{padding: PD_LG}}>
          {this.renderButton("cursor-01", colorOrange, () => {}, true)}
          {this.renderButton("rotate-02", colorOrange, () => {}, true)}
          {this.renderButton("stamp-02", colorOrange, () => {}, true)}
          {this.renderButton("scissor", colorOrange, () => {}, true)}
          {this.renderButton("camera-01", colorOrange, () => {}, true)}
          {this.renderButton("paint-bucket", colorOrange, () => {}, true)}
        </StyledBoxColumn>
      </StyledBoxRow>
      <StyledBoxRow style={{padding: PD_LG}}>
        <StyledSpan/>
        <StyledBoxColumn>
          <Grid container xs={12} sm={6}>
            <StyledBoxRow>
              {COLORS.slice(0, 12).map(color => this.renderPaletteColor(color))}
              {COLORS.slice(12, 24).map(color => this.renderPaletteColor(color))}
            </StyledBoxRow>
          </Grid>
          <Grid container xs={12} sm={6}>
            <StyledBoxRow>
              {COLORS.slice(24, 36).map(color => this.renderPaletteColor(color))}
              {COLORS.slice(36, 48).map(color => this.renderPaletteColor(color))}
            </StyledBoxRow>
          </Grid>
        </StyledBoxColumn>
        <StyledSpan/>
      </StyledBoxRow>
    </>;
  }
}

