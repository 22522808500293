import React from "react";
import {PathComponent} from "index";
import {StyledBoxColumn} from "../shared/StyledComponents";
import {PageFragment, PageFragmentProps, PageFragmentState} from "../shared/PageFragment";
import {Drawpad} from "./components/nino/Drawpad";
import {Editor} from "./components/nino/Editor";

export type MainProps = PageFragmentProps & {}

export enum MainMode {
  HOME = "home",
  DRAWPAD = "drawpad",
}

type MainState = PageFragmentState & {
  mode: MainMode,
}

export class Main extends PageFragment<MainProps, MainState> {

  constructor(props: MainProps, context: any) {
    super(props, context);
    this.state = {
      mode: MainMode.HOME,
    };
  }

  static nestedPaths(): PathComponent[] {
    return [];
  }

  private renderModeHome() {
    return <Editor setMode={mode => this.setState({mode: mode})}/>;
  }

  private renderModeDrawpad() {
    return <Drawpad setMode={mode => this.setState({mode: mode})}/>;
  }

  private renderMode() {
    switch (this.state.mode) {
      default:
      case MainMode.HOME:
        return this.renderModeHome();
      case MainMode.DRAWPAD:
        return this.renderModeDrawpad();
    }
    return null;
  }

  renderContent(): React.ReactElement {
    return <StyledBoxColumn style={{width: "100vw", height: "100vh", gap: 0}}>
      {this.renderMode()}
    </StyledBoxColumn>;
  }
}
