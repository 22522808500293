export const COLORS = [
  "rgb(255, 210, 242)",
  "rgb(255, 153, 214)",
  "rgb(255, 69, 131)",
  "rgb(195, 0, 1)",
  "rgb(255, 0, 35)",
  "rgb(255, 131, 0)",
  "rgb(255, 178, 0)",
  "rgb(255, 244, 46)",
  "rgb(255, 249, 194)",
  "rgb(226, 255, 189)",
  "rgb(207, 245, 0)",
  "rgb(80, 216, 35)",
  "rgb(41, 193, 48)",
  "rgb(43, 191, 138)",
  "rgb(2, 118, 7)",
  "rgb(17, 77, 36)",
  "rgb(255, 255, 255)",
  "rgb(204, 221, 231)",
  "rgb(97, 120, 124)",
  "rgb(28, 28, 28)",
  "rgb(216, 48, 163)",
  "rgb(255, 100, 233)",
  "rgb(217, 153, 255)",
  "rgb(161, 89, 211)",
  "rgb(114, 38, 150)",
  "rgb(20, 20, 99)",
  "rgb(0, 51, 153)",
  "rgb(29, 64, 237)",
  "rgb(0, 121, 211)",
  "rgb(0, 158, 255)",
  "rgb(118, 200, 255)",
  "rgb(172, 224, 253)",
  "rgb(17, 183, 188)",
  "rgb(33, 249, 243)",
  "rgb(195, 252, 252)",
  "rgb(84, 49, 30)",
  "rgb(142, 87, 42)",
  "rgb(228, 182, 157)",
  "rgb(255, 205, 164)",
  "rgb(255, 237, 215)",
  "#e0e0e0",
  "#c0c0c0",
  "#a0a0a0",
  "#808080",
  "#606060",
  "#404040",
  "#202020",
  "#000000",
];